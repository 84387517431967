<template>
    <div class="container">
        <div class="head-img">
            <img src="../assets/img/1_02.png">
        </div>
        <div class="content">
            <p>如何录入面部数据</p>
            <div>首先，确保摄像头清洁、面部无遮挡，不要佩戴帽子墨镜、口罩等物件。录入环境光线充足、无阳光直射，无曝光、无逆光。
            </div>
            <div>了解 <span style="color: #e85744" @click="handleClick"> 关于人脸识别的声明</span></div>
            <div class="info" v-show="show">
                <span>1、录入的人脸仅做录入项目的人脸识别系统使用</span>
                <span>2、录入的人脸存放在阿里云平台服务器中确保隐私和安全</span>
                <span>3、系统平台方承诺不将用户资料以任何形式透露给其他人</span>
                <span>4、平台方也会告知系统使用管理方:确保人员资料安全、隐私</span>
            </div>
        </div>
        <div class="bottom-style-text">
            <span class="p-text2" @click="handleClick1">开始录入</span>
        </div>
    </div>
</template>
<script>
    export default {
        name: "faceRecognition",
        data() {
            return {
                show: false
            };
        },
        methods: {
            handleClick() {
                this.show = !this.show;
            },
            handleClick1() {
                this.$router.push('/ACRegistration')
            },
        }
    }
</script>

<style scoped>
    .container {
        width: 100vw;
        height: 100vh;
        background-color: rgb(225 223 224);
        display: flex;
        flex-direction: column;
    }

    .head-img {
        height: 230px;
    }

    .head-img img {
        width: 100%;
        height: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
        line-height: 35px;
    }

    .content p {
        font-size: 19px;
        font-weight: bold;
        margin: 10px 0;
        color: #000;
    }

    .content div {
        font-size: 15px;
        text-align: left;
        color: #4e4e4e;
    }

    .content .info {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        /*background-color: white;*/
        color: #8e8e8e;
        padding: 0 5px;
    }

    .bottom-style-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5%;
    }

    .bottom-style-text .p-text2 {
        font-family: 微软雅黑;
        font-size: 16px;
        color: #fff;
        border-radius: 15px;
        padding: 8px 60px;
        background: linear-gradient(-30deg, rgb(228 87 68), rgb(232 111 80));
    }
</style>
